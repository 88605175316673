/* Spline Sans Google Font */
@import url("https://fonts.googleapis.com/css2?family=Spline+Sans:wght@300..700&display=swap");

/* Manrope Google Font */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

/* Plus Jakarta Sans Google Font */
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

/* Inter Google Font */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

/* Lato Google Font */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* Roboto Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Bootstrap Icons */
@import "/node_modules/bootstrap-icons/font/bootstrap-icons.css";

:root {
  --primary: #00067e;
  --secondary: #f96137;
}

/* Start: Global CSS Customization */
html,
body {
  /* background-color: #000; */
  color: #000;
  font-size: 16px;
  font-family: "Spline Sans", sans-serif;
  font-weight: 400;
  margin: 0;
  /* overflow-x: hidden; */
  -webkit-font-smoothing: antialiased;
}

/* Tailwind Custom Scrollbar */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #6055a0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #806fd4; /* Custom color for the scrollbar thumb */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4d6ef7; /* Hover color for the scrollbar thumb */
}

/* .custom-container {
  max-width: 976px;
} */

.text-outlined {
  text-shadow: 1px 1px 0 black, -1px -1px 0 black, -1px 1px 0 black,
    1px -1px 0 black;
}

.text-outlined-2 {
  text-shadow: 1px 1px 0 #fa6138, -1px -1px 0 #fa6138, -1px 1px 0 #fa6138,
    1px -1px 0 #fa6138;
}

/* Header CSS */
.homepage-layout header {
  position: fixed !important;
}

.homepage-layout header:not(.scrolled) nav {
  background-color: transparent !important;
}

header {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Mobile Menu Drawer CSS */
@media (max-width: 767px) {
  .homepage-layout header .right-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: #00067e;
    overflow: auto;
    z-index: 50;
  }
}

/* react-select customization */
.custom-select .css-13cymwt-control,
.custom-select .css-t3ipsp-control {
  color: #fff;
  background-color: transparent;
  border-color: #fff !important;
  border-radius: 6px;
  min-height: 45px;
  text-align: left;
  padding: 8.5px 6px;
  box-shadow: none;
}

.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue {
  color: #fff !important;
  font-size: 16px;
}
@media (min-width: 1536px) {
  .custom-select .css-13cymwt-control,
  .custom-select .css-t3ipsp-control {
    padding: 13.5px 6px;
  }
}

.loader-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Admin Sidebar CSS */
.admin_sidebar {
  transition: all 0.5s;
}

.admin-sidebar-open .admin_sidebar {
  transform: translateX(0);
}

.admin-sidebar-open {
  position: relative;
}

.admin-sidebar-open:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
  z-index: 50;
}

.admin-sidebar-link:hover img, .sidebar-pro-btn:hover img {
  filter: invert(45%) sepia(55%) saturate(1934%) hue-rotate(339deg) brightness(101%) contrast(96%);
}

.admin-main-content {
  transition: all 0.5s;
}
