@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .btn-custom {
        @apply min-w-[84px] bg-theme_clr2 text-white text-sm font-manrope font-bold px-[16.5px] py-[9.5px] rounded-[12px];
    }
    .btn-custom1 {
        @apply min-w-[84px] bg-white text-black text-sm font-manrope font-bold px-[16.5px] py-[9.5px] rounded-[12px] me-2;
    }
    .form-control {
        @apply w-full bg-transparent text-white placeholder:text-white border border-white rounded-[6px] focus:outline-none px-4 py-[14px] 2xl:py-[19px];
    }
    .form-check {
        @apply h-[18px] w-[16px] min-w-[16px] bg-transparent border border-white rounded-[6px] focus:outline-none mt-[2px];
    }
    .btn-custom2 {
        @apply text-white text-sm font-jakarta font-semibold bg-gradient-to-t from-theme_clr1 to-[#4C63D2] rounded-full px-5 py-4;
    }
    .btn-custom3 {
        @apply text-white text-base font-spline font-bold bg-gradient-to-r from-theme_clr2 from-[0%] to-[#FFB700] rounded-xl px-4 py-[6px];
    }
    .btn-custom4 {
        @apply text-white text-base font-spline font-bold bg-gradient-to-b from-theme_clr2 from-[0] to-[#FFC107] to-[100%] rounded-lg px-4 py-[8px];
    }
    .admin-sidebar-link {
        @apply bg-theme_clr1 text-sm text-white hover:text-theme_clr2 font-medium flex items-center gap-2 w-full capitalize transition-all rounded-xl px-3 py-2 md:py-3 2xl:py-5;
    }
}