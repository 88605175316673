input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
    height: 15px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
    background: rgba(255, 61, 0, 0.38);
    border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
    background: rgba(255, 61, 0, 0.38);
    border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #FF3D00;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #FF3D00;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #FF3D00;
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px #FF3D00;
}